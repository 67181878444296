<template>
    <div>
        <span class="control has-icons-right has-icons-left">
            <input
                class="h-9 border rounded-md pl-10 w-full text-sm"
                v-bind="$attrs"
                v-model="valueLocal"
                v-on="inputListeners"
                placeholder="0,00"
                @keydown.enter="enterPressed"
                @keydown.tab="tabPressed"
                @keypress="checkOperation"
            />
            <span v-if="tempVal" class="icon text-sm is-right" style="width:30%"> = {{ tempVal }}</span>
            <span class="icon is-right pb-4">{{ rightText }} </span>
            <span class="icon is-left pb-4"><i class="fa-regular fa-calculator fa-lg"/></span>
            <span class="icon is-small is-right pb-4">
                <i :class="rightIcon" />
            </span>
        </span>
        <p v-if="error" class="help is-danger">{{ error }}</p>
    </div>
</template>
<script>
export default {
    props: ["error", "value", "rightText", "rightIcon", "allowZeroStart"],

    data() {
        return {
            isOperation: false,
        };
    },

    methods: {
        checkOperation() {
            if (this.valueLocal.includes("+") || this.valueLocal.includes("-") || this.valueLocal.includes("*") || this.valueLocal.includes("/")) {
                this.isOperation = true;
            }
        },

        calculate() {
            try {
                let amount = this.valueLocal.split(",").join(".");
                this.valueLocal = eval(amount)
                    .toFixed(2)
                    .toString();
            } catch {
                this.valueLocal = "";
            }
            this.isOperation = false;
        },

        tabPressed() {
            this.calculate();
        },

        enterPressed() {
            this.calculate();
        },

        getPosition(string, subString, index) {
            return string.split(subString, index).join(subString).length;
        },

        replaceChar(origString, replaceChar, index) {
            let firstPart = origString.substr(0, index);
            let lastPart = origString.substr(index + 1);
            let newString = firstPart + replaceChar + lastPart;
            return newString;
        },
    },
    computed: {
        tempVal() {
            if (this.isOperation) {
                try {
                    let amount = this.valueLocal.split(",").join(".");
                    return parseFloat(eval(amount))
                        .toFixed(2)
                        .toString()
                        .replace(".", ",");
                } catch {
                    return "";
                }
            }
            return "";
        },

        valueLocal: {
            get() {
                let amount = "0";
                if (this.value != null) {
                    amount = this.value.toString();
                }
                // eslint-disable-next-line no-useless-escape
                amount = amount.replace(/[^0-9\/\*\+\.,-]/g, "");
                // amount = amount.replace(/[^0-9\.][-+]/g, "");
                amount = amount.split(".").join(",");
                if (!this.isOperation) {
                    var commaIndex = this.getPosition(amount, ",", 2);
                    amount = this.replaceChar(amount, "", commaIndex);
                }

                var commaZeroIndex = amount.indexOf(",");
                if (commaZeroIndex == 0) {
                    amount = this.replaceChar(amount, "", commaZeroIndex);
                }
                return amount;
            },
            set(newValue) {
                // eslint-disable-next-line no-useless-escape
                this.$emit("input", newValue.replace(/[^0-9\.,-]/g, ""));
            },
        },

        inputListeners: function() {
            var vm = this;
            return Object.assign({}, this.$listeners, {
                input: function(event) {
                    vm.$emit("input", event.target.value.replace(",", "."));
                },
            });
        },
    },
};
</script>
<style scoped>
.input {
    font-size: 15px;
}
</style>
